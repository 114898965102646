import EventEmitter from 'events';
import WaveSurfer from 'wavesurfer.js';
import { create } from 'zustand';
import { SoloMutedStems } from '../@types/songs';

type PlaylistStatePerSong = {
  eventEmitter: EventEmitter;
  playlist: any;
  waveSurfer: WaveSurfer | null;
  loadedStemsCount: number;
  stemsCountForLoading: number;
  isSongRendered: boolean;
  isStemsRendered: boolean;
  songId: string;
  versionId: string;
  soloMutedStems: Map<string, SoloMutedStems> | null;
  songUrl: string;
};
type PlaylistState = { playListStates: PlaylistStatePerSong[] };
type PlaylistActions = {
  init: (versionId: string) => PlaylistStatePerSong | null | undefined;
  initPlaylist: (playlist: any, versionId: string) => void;
  initWavesurfer: (waveSurfer: WaveSurfer | null, songUrl: string, versionId: string) => void;
  clearLoadedStemsCount: (versionId: string) => void;
  incrLoadedStemsCount: (versionId: string) => void;
  setStemsCountForLoading: (stemsCount: number, versionId: string) => void;
  handleIsSongRendered: (isSongRendered: boolean, versionId: string) => void;
  handleIsStemsRendered: (isStemsRendered: boolean, versionId: string) => void;
  setSoloMutedStems: (soloMutedStems: Map<string, SoloMutedStems>, versionId: string) => void;
  clearStemPlayers: () => void;
};

export const usePlaylistStore = create<PlaylistState & PlaylistActions>((set, get) => ({
  playListStates: [],
  handleIsSongRendered: (isSongRendered, versionId) =>
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, isSongRendered };
          }
          return state;
        })
      ]
    }),
  handleIsStemsRendered: (isStemsRendered, versionId) =>
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, isStemsRendered };
          }
          return state;
        })
      ]
    }),
  clearLoadedStemsCount: versionId =>
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, loadedStemsCount: 0, stemsCountForLoading: 0, isStemsRendered: false };
          }
          return state;
        })
      ]
    }),
  incrLoadedStemsCount: (versionId: string) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, isStemsRendered:state.loadedStemsCount + 1 >=state.stemsCountForLoading, loadedStemsCount: state.loadedStemsCount + 1 };
          }
          return state;
        })
      ]
    });
  },
  setStemsCountForLoading: (stemsCount, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, stemsCountForLoading: stemsCount };
          }
          return state;
        })
      ]
    });
  },
  setSoloMutedStems: (soloMutedStems, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, soloMutedStems };
          }
          return state;
        })
      ]
    });
  },
  clearStemPlayers: () => {
    get().playListStates.forEach(state => {
      state.eventEmitter.emit('clear');
      state.eventEmitter.removeAllListeners();
      state.playlist = null;
      state.loadedStemsCount = 0;
      state.stemsCountForLoading = 0;
      state.isStemsRendered = false;
    });
  },
  init: (versionId) => {
    if (versionId && !get().playListStates.find(state => state.versionId === versionId)) {
      const newState = [
        ...get().playListStates,
        {
          eventEmitter: new EventEmitter(),
          playlist: null,
          waveSurfer: null,
          totalStemsCount: 0,
          loadedStemsCount: 0,
          stemsCountForLoading: 0,
          isSongRendered: false,
          isStemsRendered: false,
          songId: '',
          versionId,
          soloMutedStems: null,
          songUrl: ''
        }
      ];
      if (newState.length > 4) {
        const removedState = newState.shift();
        if (removedState) {
          removedState.eventEmitter.emit('clear');
          removedState.eventEmitter.removeAllListeners();
          removedState.waveSurfer?.empty();
          removedState.waveSurfer?.destroy();

          removedState.soloMutedStems?.clear();

          removedState.soloMutedStems = null;
          removedState.playlist = null;
          removedState.waveSurfer = null;
        }
      }
      set({
        playListStates: newState
      });
    }

    return get().playListStates.find(state => state.versionId === versionId);
  },
  initPlaylist: (playlist, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, playlist };
          }
          return state;
        })
      ]
    });
  },

  initWavesurfer: (waveSurfer, songUrl, versionId) => {
    set({
      playListStates: [
        ...get().playListStates.map(state => {
          if (state.versionId === versionId) {
            return { ...state, waveSurfer, songUrl };
          }
          return state;
        })
      ]
    });
  }
}));
