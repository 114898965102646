import { FolderNode } from './fileUtils';

const processUnZipFile = async (zipFile: Blob): Promise<FolderNode[]> => {
  return new Promise((resolve, reject) => {
    const worker = new Worker(new URL('../workers/unzipWorker.ts', import.meta.url));
    worker.onmessage = e => {
      const { zippedFile, error } = e.data;
      if (error) {
        reject(new Error(error));
      } else {
        resolve(zippedFile);
      }
    };

    worker.onerror = error => {
      reject(new Error(error.message));
    };

    worker.postMessage({ zipFile: zipFile });
  });
};

export { processUnZipFile };
