import { Typography } from '@mui/material';
import { Navigate, Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';
import NotFoundComponent from '../components/NotFoundPage';
import { UploadPage } from '../components/UploadPage';
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import ShareRedirect from '../layouts/DashboardLayout/components/ShareRedirect';
import MainLayout from '../layouts/MainLayout/MainLayout';
import { MainSongPage } from '../pages/MainSongPage';
import { VerificationEmailPage } from '../pages/VerificationEmailPage';
import { AuthGuard } from '../utils/AuthGuard';
import ShareFolderRedirect from '../layouts/DashboardLayout/components/ShareFolderRedirect';
import Account from '../pages/Account';
import Plans from '../pages/Plans';
import Billings from '../pages/billings';
import { RequestAccessPage } from '../pages/RequestAccessPage';

const routes: RouteObject[] = [
  {
    path: '',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    errorElement: <></>,
    children: [
      { path: '', element: <Navigate to="dashboard" replace /> },
      { path: 'email-verification', element: <VerificationEmailPage /> },
      { path: 'about', element: <Typography>Coming soon...</Typography> },
      { path: 'share/song/:songId/:type', element: <ShareRedirect />, index: true },
      { path: 'share/folder/:folderId/:type', element: <ShareFolderRedirect />, index: true },
      {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
          { path: '', element: <UploadPage />, index: true },
          {
            path: ':folderId',
            element: <Outlet />,
            children: [
              { path: '', element: <UploadPage />, index: true },
              { path: ':songId', element: <MainSongPage /> }
            ]
          },
          {
            path: 'public',
            element: <Outlet />,
            children: [
              {
                path: ':folderId',
                element: <Outlet />,
                children: [
                  { path: '', element: <UploadPage />, index: true },
                  { path: ':songId', element: <MainSongPage /> }
                ]
              }
            ]
          }
        ]
      },
      { path: 'request-access', element: <RequestAccessPage /> },
      { path: 'account', element: <DashboardLayout />, children: [{ path: '', element: <Account />, index: true }] },
      { path: 'plans', element: <DashboardLayout />, children: [{ path: '', element: <Plans />, index: true }] },
      { path: 'billings', element: <DashboardLayout />, children: [{ path: '', element: <Billings />, index: true }] },
      { path: 'termsofservices', element: <Typography>To be terms of services page...</Typography> },
      { path: 'privacypolicy', element: <Typography>To be privacy policy page...</Typography> },
      {
        path: '*',
        element: <NotFoundComponent />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);
