import { folder } from 'jszip';
import { Folder, GetFoldersResponse, SidebarFolder } from '../@types/folders';
import axios from '../utils/axios';
import { Song } from '../@types/songs';

const getFolders = async (): Promise<Folder[]> => {
  return await axios.get<GetFoldersResponse>('folders').then(data => data.data.folders);
};

const createFolder = async (name: string, parentFolderId?: string): Promise<Folder> => {
  return await axios.post('folders/create', { name, parentFolderId }).then(res => res.data);
};

const renameFolder = async (folderId: string, folderName: string) => {
  await axios.post(`folders/${folderId}/rename`, { folderName });
};

const getFolderById = async (folderId: string): Promise<Folder> => {
  return await axios.get(`folders/${folderId}`).then(res => res.data);
};

const deleteFolder = async (folderId: string) => {
  await axios.delete(`folders/${folderId}`);
};

const getFolderSongWithAccess = async (folderId: string): Promise<Song[]> => {
  return await axios.get(`folders/${folderId}/song-with-access`).then(res => res.data);
};

const folderService = { getFolders, createFolder, renameFolder, deleteFolder, getFolderById, getFolderSongWithAccess };

export default folderService;
