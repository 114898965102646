import { SharedUserRoles } from "../@types/sharing";

export const accessType = {
  canDownload(accessType: SharedUserRoles | null) {
    if (!accessType) return false;
    return accessType === SharedUserRoles.Downloader || accessType === SharedUserRoles.Editor || accessType === SharedUserRoles.Owner;
  },
  
  canEdit(accessType: SharedUserRoles | null) {
    if (!accessType) return false;
    return accessType === SharedUserRoles.Editor || accessType === SharedUserRoles.Owner;
  },
  isOwner(accessType: SharedUserRoles | null) {
    if (!accessType) return false;
    return accessType === SharedUserRoles.Owner;
  },
  canView(accessType: SharedUserRoles | null) {
    if(!accessType) return false;
    return accessType === SharedUserRoles.Viewer || accessType === SharedUserRoles.Downloader || accessType === SharedUserRoles.Editor || accessType === SharedUserRoles.Owner;
  }
};