import { create } from 'zustand';
import { Folder, SidebarFolder } from '../@types/folders';
import { MoveSongRequest, Song } from '../@types/songs';
import folderService from '../services/folderService';
import songsService from '../services/songsService';
import sharingService from '../services/sharingService';
import { SharedUserInfo } from '../@types/sharing';

type FoldersStoreState = {
  folders: SidebarFolder[];
  sharedFolderUsers: SharedUserInfo[];
  foldersError: Error | string | null;
  isFoldersLoading: boolean;
  isRenameLoading: string;
};

type FoldersStoreActions = {
  getFolders: () => Promise<Folder[]>;
  createFolder: (name: string, parentFolderId?: string) => Promise<SidebarFolder | undefined>;
  deleteFolder: (folderId: string) => Promise<void>;
  renameFolder: (folderId: string, newName: string) => Promise<void>;
  renameSongInSidebar: (folderId: string, songId: string, newName: string) => void;
  renameSong: (folderId: string, songId: string, newName: string) => Promise<void>;
  createSongInFolder: (folderId: string, song: Song) => void;
  deleteFolderSong: (folderId: string, songId: string) => void;
  moveFolderSong: (data: MoveSongRequest) => Promise<void>;
  shareFolderByInvite: (data: any) => Promise<void>;
  getFolderSharedUsers: (folderId: string) => Promise<void>;
  deleteFolderSharedUser: (email: string, folderId: string) => Promise<void>;
  toggleFolder: (folderId: string) => void;
  setFolders: (folders: SidebarFolder[]) => void;
  openFolder: (folderId: string) => void;
};
export const defaultFolderName = 'default_folder_for_uploads';
export const useFoldersStore = create<FoldersStoreState & FoldersStoreActions>((set, get, store) => ({
  folders: [],
  sharedFolderUsers: [],
  foldersError: null,
  isFoldersLoading: false,
  isRenameLoading: '',
  setFolders: (folders: SidebarFolder[]) => {
    set({
      folders
    });
  },
  getFolders: async () => {
    try {
      set({ isFoldersLoading: true });

      const folders = await folderService.getFolders();

      const addIsOpenProperty = (folders: Folder[]): SidebarFolder[] => {
        return folders.map(folder => ({
          ...folder,
          isOpen: false,
          children: addIsOpenProperty(folder.children)
        }));
      };

      const modifiedFolders = addIsOpenProperty(folders);

      set({ folders: modifiedFolders, isFoldersLoading: false, foldersError: null });

      return folders;
    } catch (e: any) {
      set({ folders: [], foldersError: e.message, isFoldersLoading: false });
      return [];
    }
  },
  createFolder: async (name, parentFolderId) => {
    try {
      const folder = await folderService.createFolder(name, parentFolderId);

      set(state => {
        const addFolderToTree = (folders: SidebarFolder[], parentFolderId?: string): SidebarFolder[] => {
          return folders.map(f => {
            if (f.id === parentFolderId) {
              return {
                ...f,
                children: [...f.children, { ...folder, isOpen: false, children: [] }]
              };
            }

            return {
              ...f,
              children: addFolderToTree(f.children, parentFolderId)
            };
          });
        };

        const updatedFolders = parentFolderId
          ? addFolderToTree(state.folders, parentFolderId)
          : [...state.folders, { ...folder, isOpen: false, children: [] }];

        return { folders: updatedFolders, foldersError: null };
      });

      return { ...folder, isOpen: false, children: [] };
    } catch (e: any) {
      set(state => ({
        folders: [...state.folders],
        foldersError: e.message
      }));
    }
  },
  deleteFolder: async (folderId: string) => {
    try {
      await folderService.deleteFolder(folderId);

      const deleteFolderFromStructure = (folders: SidebarFolder[], folderId: string): SidebarFolder[] => {
        return folders
          .filter(folder => folder.id !== folderId)
          .map(folder => ({
            ...folder,
            children: folder.children ? deleteFolderFromStructure(folder.children, folderId) : []
          }));
      };

      set(state => ({
        folders: deleteFolderFromStructure(state.folders, folderId),
        foldersError: null
      }));
    } catch (e: any) {
      set(state => ({
        folders: [...state.folders],
        foldersError: e.message
      }));
    }
  },
  renameFolder: async (folderId, newName) => {
    const updateFolderName = (folders: SidebarFolder[]): SidebarFolder[] => {
      return folders.map(folder => {
        if (folder.id === folderId) {
          return { ...folder, name: newName };
        }

        if (folder.children) {
          return {
            ...folder,
            children: updateFolderName(folder.children)
          };
        }

        return folder;
      });
    };

    try {
      set({ isRenameLoading: folderId });

      await folderService.renameFolder(folderId, newName);

      set(state => ({
        folders: updateFolderName(state.folders),
        foldersError: null,
        isRenameLoading: ''
      }));
    } catch (e: any) {
      // Handle any errors during renaming
      set({
        folders: [...get().folders],
        foldersError: e.message
      });
    }
  },
  renameSongInSidebar: async (folderId, songId, newName) => {
    try {
      const renameSong = (folders: SidebarFolder[], folderId: string, songId: string, newName: string): SidebarFolder[] => {
        return folders.map(folder => {
          if (folder.id === folderId) {
            const updatedSongs = folder.songs.map(song => (song.id === songId ? { ...song, name: newName } : song));

            return {
              ...folder,
              songs: updatedSongs
            };
          } else if (folder.children) {
            return {
              ...folder,
              children: renameSong(folder.children, folderId, songId, newName)
            };
          }

          return folder;
        });
      };

      set(state => ({
        folders: renameSong(state.folders, folderId, songId, newName)
      }));
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  renameSong: async (folderId, songId, newName) => {
    try {
      get().renameSongInSidebar(folderId, songId, newName);
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  createSongInFolder: (folderId: string, song: Song) => {
    const addSongToFolder = (folders: SidebarFolder[], folderId: string, song: Song): SidebarFolder[] => {
      return folders.map(folder => {
        if (folder.id === folderId) {
          const songExists = folder.songs.some(existingSong => existingSong.id === song.id);

          if (!songExists) {
            return {
              ...folder,
              songs: [...folder.songs, song]
            };
          }
          return folder;
        } else if (folder.children) {
          return {
            ...folder,
            children: addSongToFolder(folder.children, folderId, song)
          };
        }

        return folder;
      });
    };

    set(state => ({
      folders: addSongToFolder(state.folders, folderId, song)
    }));
  },
  deleteFolderSong: (folderId: string, songId: string) => {
    const deleteSongFromFolder = (folders: SidebarFolder[], folderId: string, songId: string): SidebarFolder[] => {
      return folders.map(folder => {
        if (folder.id === folderId) {
          return {
            ...folder,
            songs: folder.songs.filter(song => song.id !== songId)
          };
        } else if (folder.children) {
          return {
            ...folder,
            children: deleteSongFromFolder(folder.children, folderId, songId)
          };
        }

        return folder;
      });
    };

    set(state => ({
      folders: deleteSongFromFolder(state.folders, folderId, songId)
    }));
  },
  moveFolderSong: async data => {
    try {
      await songsService.moveSong(data);

      const song = get()
        .folders.find(folder => folder.id === data.currentFolderId)
        ?.songs.find(song => song.id === data.songId);

      if (song) {
        song.folderId = data.newFolderId;

        get().deleteFolderSong(data.currentFolderId, data.songId);

        set({
          folders: get().folders.map(folder =>
            folder.id === data.newFolderId
              ? {
                  ...folder,
                  songs: [...folder.songs, song]
                }
              : folder
          )
        });
      }
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  shareFolderByInvite: async data => {
    try {
      await sharingService.shareFolderByInvite(data);

      await get().getFolderSharedUsers(data.folderId);
    } catch (error) {}
  },
  getFolderSharedUsers: async folderId => {
    try {
      const sharedFolderUsers = await sharingService.getSharedUsersByFolderId(folderId);

      set({ sharedFolderUsers, foldersError: null });
    } catch (e: any) {
      set({ sharedFolderUsers: [], foldersError: e.message });
    }
  },
  deleteFolderSharedUser: async (email: string, folderId: string) => {
    try {
      await sharingService.deleteFolderSharedUser(email, folderId);

      set({ sharedFolderUsers: get().sharedFolderUsers.filter(sharedUser => sharedUser.email !== email) });
    } catch (e: any) {
      set({ foldersError: e.message });
    }
  },
  toggleFolder: (folderId: string) => {
    set(state => {
      const updateFolders = (folders: SidebarFolder[], targetId: string | null, level: number): SidebarFolder[] => {
        return folders.map(folder => {
          if (folder.id === targetId) {
            return {
              ...folder,
              isOpen: !folder.isOpen,
              children: folder.isOpen ? closeAllChildren(folder.children) : updateFolders(folder.children, targetId, level + 1)
            };
          }

          const shouldClose = targetId !== null && getFolderLevel(targetId, state.folders) === level;
          return {
            ...folder,
            isOpen: shouldClose ? false : folder.isOpen,
            children: updateFolders(folder.children, targetId, level + 1)
          };
        });
      };

      const closeAllChildren = (folders: SidebarFolder[]): SidebarFolder[] => {
        return folders.map(folder => ({
          ...folder,
          isOpen: false,
          children: closeAllChildren(folder.children)
        }));
      };

      const getFolderLevel = (id: string, folders: SidebarFolder[], level: number = 0): number => {
        for (const folder of folders) {
          if (folder.id === id) {
            return level;
          }
          const childLevel = getFolderLevel(id, folder.children, level + 1);
          if (childLevel >= 0) {
            return childLevel;
          }
        }
        return -1;
      };

      return { folders: updateFolders(state.folders, folderId, 0) };
    });
  },
  openFolder: (folderId: string) =>
    set(state => {
      const openFolderById = (folders: SidebarFolder[]): boolean => {
        for (const folder of folders) {
          if (folder.id === folderId) {
            folder.isOpen = true;
            return true;
          }

          if (folder.children.length > 0) {
            const childOpened = openFolderById(folder.children);
            if (childOpened) {
              folder.isOpen = true;
              return true;
            }
          }
        }
        return false;
      };

      const updatedFolders = [...state.folders];

      openFolderById(updatedFolders);

      return { folders: updatedFolders };
    })
}));
