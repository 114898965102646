import { useUploadFilesStore } from '../uploadFIles.store';

export function useUploadFilesSelector() {
  // State
  const uploadingSongs = useUploadFilesStore(state => state.uploadingSongs);
  const lastQueue = useUploadFilesStore(state => state.lastQueue);
  const currentPreparedSharedUsers = useUploadFilesStore(state => state.currentPreparedSharedUsers);
  // Actions

  const setUploadData = useUploadFilesStore(state => state.setUploadData);

  const uploadSong = useUploadFilesStore(state => state.uploadSong);
  const uploadStem = useUploadFilesStore(state => state.uploadStem);
  const createParentSong = useUploadFilesStore(state => state.createParentSong);
  const handleProgressForSong = useUploadFilesStore(state => state.handleProgressForSong);
  const handleProgressForStems = useUploadFilesStore(state => state.handleProgressForStems);
  const handleEstimatedTimeForSong = useUploadFilesStore(state => state.handleEstimatedTimeForSong);
  const handleEstimatedTimeForStems = useUploadFilesStore(state => state.handleEstimatedTimeForStems);
  const handleTotalEstimatedTime = useUploadFilesStore(state => state.handleTotalEstimatedTime);
  const handleTotalProgress = useUploadFilesStore(state => state.handleTotalProgress);
  const addUploadStems = useUploadFilesStore(state => state.addUploadStems);
  const removeUploadSong = useUploadFilesStore(state => state.removeUploadSong);
  const addPreparedSharedUser = useUploadFilesStore(state => state.addPreparedSharedUser);
  const deletePreparedSharedUser = useUploadFilesStore(state => state.deletePreparedSharedUser);

  return {
    uploadingSongs,
    setUploadData,
    uploadSong,
    uploadStem,
    createParentSong,
    handleProgressForSong,
    handleProgressForStems,
    handleEstimatedTimeForSong,
    handleEstimatedTimeForStems,
    handleTotalEstimatedTime,
    handleTotalProgress,
    addUploadStems,
    lastQueue,
    removeUploadSong,
    addPreparedSharedUser,
    deletePreparedSharedUser,
    currentPreparedSharedUsers,
  };
}
