import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  ButtonBase,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Folder, SidebarFolder } from '../../@types/folders';
import { SharedFolderItem } from '../../@types/sharing';
import { Song } from '../../@types/songs';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import authService from '../../services/authService';
import folderService from '../../services/folderService';
import songsService from '../../services/songsService';
import trackEvent from '../../services/trackService';
import sharedService from '../../services/sharingService';
import { defaultFolderName } from '../../store/folders.store';
import { useFoldersSelector } from '../../store/selectors/folders.selector';
import { useSidebarStore } from '../../store/sidebar.store';
import { useUserStore } from '../../store/user.store';
import CreateDropDownMenu from './Menus/CreateDropDownMenu';
import FolderDropDown from './components/FolderDropDown';
import FolderDropDownItemsList from './components/FolderDropDownItemsList';
import { SharedDropDown } from './components/SharedDropDown';
import SharedFolderDropDown from './components/SharedFolderDropDown';
import './scrollbar.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import paymentService from '../../services/paymentService';

export default function DashboardLayout() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const user = useUserStore(state => state.user);
  const getUser = useUserStore(state => state.getUser);
  const [movingSongToFolder, setMovingSongToFolder] = useState<null | { folderName: string; songName: string }>(null);
  const { folders, isFoldersLoading, getFolders, defaultFolder, moveFolderSong, setFolders, openFolder, createFolder } =
    useFoldersSelector();
  const containerRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const { folderId, songId } = useParams();
  const location = useLocation();
  const [highlightedSong, setHighlightedSong] = useState('');
  const isSidebarOpened = useSidebarStore(state => state.isSidebarOpened);
  const toggleSidebarMobile = useSidebarStore(state => state.toggleSidebar);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user: userAuth0 } = useAuth0();
  const [isEmailVerified, setIsEmailVerified] = useState<undefined | boolean>(undefined);
  const isPublicLocation = location.pathname.includes('public');
  const params = new URLSearchParams(location.search);

  const [usedStorage, setUsedStorage] = useState<number>(0);
  const [userPlan, setUserPlan] = useState<string | undefined>('');

  useEffect(() => {
    if (userAuth0) {
      authService.getUsedStorage().then(data => setUsedStorage(data));
      setInterval(() => authService.getUsedStorage().then(data => setUsedStorage(data)), 5000);
    }
  }, [userAuth0]);

  useEffect(() => {
    if (!isPublicLocation) {
      getUser();
      setFolders([]);

      if (userAuth0) {
        authService.checkEmailVerification().then(emailVerification => setIsEmailVerified(emailVerification));
      }

      getFolders().then(async folders => {
        if (!folders.length) {
          await createFolder(defaultFolderName);

          if (user) {
            navigate('/dashboard');
          }
        }
      });
    }
  }, [userAuth0, isPublicLocation]);

  useEffect(() => {
    if (typeof isEmailVerified === 'boolean' && !isEmailVerified) {
      navigate('/email-verification');
    }
  }, [isEmailVerified]);

  useEffect(() => {
    const isFolderShare = params.get('isfs');

    if (isFolderShare) {
      localStorage.setItem('isfs', isFolderShare);
    }

    if (isPublicLocation && folderId && isFolderShare !== 'false') {
      sharedService.getFolderAccessType(folderId).then(accessType => {
        folderService.getFolderById(folderId).then(folder => {
          const addIsOpenProperty = (folders: Folder[]): SidebarFolder[] => {
            return folders.map(folder => ({
              ...folder,
              isOpen: false,
              children: addIsOpenProperty(folder.children)
            }));
          };

          const modifiedFolders = addIsOpenProperty([folder]);

          setFolders(modifiedFolders.map(f => ({ ...f, name: f.name + '(public)', isOpen: true })));
        });
      });
    } else if (isPublicLocation && songId && isFolderShare !== 'true') {
      songsService.getSongById(songId!).then(song => {
        setFolders([{ id: 'default_folder_for_uploads', name: 'default_folder_for_uploads', isOpen: false, children: [], songs: [song] }]);
        setHighlightedSong(song.id ?? '');
      });
    }
  }, [isPublicLocation]);

  useEffect(() => {
    if (params.get('open') === 'true') {
      setIsSidebarHidden(false);

      if (folders) {
        if (folderId) openFolder(folderId);
      }
    }
  }, [location, folders.length]);

  useEffect(() => {
    fetchPlan();
  }, []);

  const handleDropMusicToDefaultFolder = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const draggedData = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (defaultFolder && draggedData.folderId === defaultFolder.id) return;

    setMovingSongToFolder({ songName: draggedData.songName, folderName: 'root' });
    await moveFolderSong({ songId: draggedData.songId, currentFolderId: draggedData.folderId, newFolderId: defaultFolder!.id });

    enqueueSnackbar('Song moved successfully', { variant: 'success' });

    setMovingSongToFolder(null);
  };

  const toggleSidebar = () => {
    setIsSidebarHidden(!isSidebarHidden);
  };

  const fetchPlan = async () => {
    paymentService.getUserPlan().then(userPlan => {
      setUserPlan(userPlan?.plan);
    });
  };

  const storageLimit = userPlan === 'Premium' ? '2 TB' : userPlan === 'Creator' ? '10 TB' : '2 GB';

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!movingSongToFolder}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: 'linear-gradient(270deg, #008EF3 0%, #16E3F5 100%)'
          }
        }}
        message={"Moving song '" + movingSongToFolder?.songName + "' to " + "'" + movingSongToFolder?.folderName + "'" + ' folder...'}
      />
      {isMobile ? (
        <>
          <Drawer
            open={isSidebarOpened}
            onClose={toggleSidebarMobile}
            PaperProps={{ sx: { backgroundColor: '#151515', backgroundImage: 'unset' } }}
          >
            <Box
              sx={{
                width: 340,
                height: '100%',
                maxHeight: '100vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                pb: 2
              }}
              role="presentation"
              onClick={toggleSidebarMobile}
            >
              <List>
                <Grid container p={1.25}>
                  <Grid item xs>
                    <Grid container columnSpacing={2.25} alignItems={'center'}>
                      <Grid item xs={'auto'}>
                        <AvatarGroup max={2}>
                          <Avatar sx={{ width: 28, height: 28 }}>
                            <AccountCircleIcon />
                          </Avatar>
                        </AvatarGroup>
                      </Grid>
                      <Grid item xs>
                        <Stack direction={'column'}>
                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={16}
                            sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                          >
                            {userAuth0?.name ?? userAuth0?.email}
                          </Typography>
                          {userAuth0?.email && userAuth0.name !== userAuth0?.email ? (
                            <Typography fontSize={14} color="text.secondary">
                              {userAuth0?.email}
                            </Typography>
                          ) : null}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={'auto'}></Grid>
                </Grid>
                <Divider />
                <Box>
                  <ListItem disablePadding>
                    <ListItemButton sx={{ p: 2.5 }} onClick={() => navigate('/dashboard')}>
                      <UploadIcon width={24} height={24} />
                      <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                        Upload
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </Box>
                <Divider />
                <Stack sx={{ backgroundColor: '#151515' }} height={1} borderRadius={2} flexDirection="column">
                  <Stack p={2} flexGrow={1}>
                    <CreateDropDownMenu />
                    <Grid container direction={'column'} flexGrow={1} mt={2.5}>
                      <Box ref={containerRef} />
                      {isFoldersLoading ? (
                        <Grid item>
                          <Typography mt={1}>Loading...</Typography>
                        </Grid>
                      ) : null}
                      <SimpleBar
                        style={{
                          maxHeight: 'calc(100vh - 300px)',
                          width: '100%'
                        }}
                      >
                        <Grid rowSpacing={1.25} container direction={'column'}>
                          {folders?.map(folder => (
                            <Grid item key={folder.id} maxWidth={'100% !important'}>
                              <FolderDropDown
                                folder={folder}
                                setMovingSongToFolder={setMovingSongToFolder}
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                isSharingFolder={folder.id === 'shared_folder' || isPublicLocation}
                                isPublicLocation={isPublicLocation}
                              />
                            </Grid>
                          ))}
                          <Grid
                            maxWidth={'100% !important'}
                            item
                            onDragOver={e => e.preventDefault()}
                            onDrop={handleDropMusicToDefaultFolder}
                          >
                            {defaultFolder ? (
                              <FolderDropDownItemsList
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                folder={defaultFolder!}
                              />
                            ) : null}
                          </Grid>
                        </Grid>
                      </SimpleBar>
                      <Grid onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder} flexGrow={1}></Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </List>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={6}>
                  <Typography sx={{ padding: '8px 8px', opacity: '50%', fontSize: '14px' }}>
                    {usedStorage} GB of {storageLimit} used
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <ButtonBase
                    sx={{
                      py: 0.875,
                      px: 1.5,
                      border: '1px solid #494949',
                      borderRadius: 1,
                      background: `linear-gradient(#131414 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                      borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                    }}
                    onClick={event => {
                      event.preventDefault();

                      navigate('/plans');
                    }}
                  >
                    <Typography
                      color="transparent"
                      sx={{
                        fontSize: '14px',
                        background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                        '-webkit-background-clip': 'text',
                        backgroundClip: 'text'
                      }}
                    >
                      Get more storage
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
          <Outlet />
        </>
      ) : null}
      {!isMobile ? (
        <Grid container p={3} spacing={2} flexGrow={1}>
          {!isSidebarHidden ? (
            <Grid item xs={2.5}>
              <Stack sx={{ backgroundColor: '#151515' }} height={1} borderRadius={2} flexDirection="column">
                <Stack p={2} flexGrow={1}>
                  <CreateDropDownMenu />
                  <Grid container direction={'column'} flexGrow={1} mt={1.25}>
                    <Box ref={containerRef} />
                    {isFoldersLoading ? (
                      <Grid item>
                        <Typography mt={1}>Loading...</Typography>
                      </Grid>
                    ) : null}
                    <SimpleBar
                      style={{
                        maxHeight: 'calc(100vh - 230px)',
                        width: '100%'
                      }}
                    >
                      <Grid rowSpacing={1.25} container direction={'column'}>
                        {folders?.map(folder => (
                          <Grid item key={folder.id} maxWidth={'100% !important'}>
                            <FolderDropDown
                              folder={folder}
                              setMovingSongToFolder={setMovingSongToFolder}
                              highlightedSongId={highlightedSong}
                              setHighlightedSongId={setHighlightedSong}
                              isSharingFolder={folder.id === 'shared_folder' || isPublicLocation}
                              isPublicLocation={isPublicLocation}
                            />
                          </Grid>
                        ))}

                        <Grid
                          maxWidth={'100% !important'}
                          item
                          onDragOver={e => e.preventDefault()}
                          onDrop={handleDropMusicToDefaultFolder}
                        >
                          {defaultFolder ? (
                            <FolderDropDownItemsList
                              highlightedSongId={highlightedSong}
                              setHighlightedSongId={setHighlightedSong}
                              folder={defaultFolder!}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </SimpleBar>
                    <Grid onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder} flexGrow={1}></Grid>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={'auto'}>
                      <Typography sx={{ padding: '4px 8px', opacity: '50%', fontSize: '12px' }}>
                        {usedStorage} GB of {storageLimit} used
                      </Typography>
                    </Grid>
                    <Grid item xs={'auto'}>
                      <ButtonBase
                        sx={{
                          py: 0.875,
                          px: 1.5,
                          border: '1px solid #494949',
                          borderRadius: 1,
                          background: `linear-gradient(#131414 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                          borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                        }}
                        onClick={event => {
                          event.preventDefault();

                          navigate('/plans');
                        }}
                      >
                        <Typography
                          color="transparent"
                          sx={{
                            fontSize: '14px',
                            background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                            '-webkit-background-clip': 'text',
                            backgroundClip: 'text'
                          }}
                        >
                          Get more storage
                        </Typography>
                      </ButtonBase>
                    </Grid>
                  </Grid>{' '}
                </Stack>
              </Stack>
            </Grid>
          ) : null}
          <Grid item xs={!isSidebarHidden ? 9.5 : 12}>
            <Stack height={1} borderRadius={1}>
              <Tooltip title={'Files & Folders'}>
                <IconButton
                  disableRipple
                  disableFocusRipple
                  sx={{
                    position: 'absolute',
                    left: '30px',
                    top: '88%',
                    zIndex: 999,
                    width: '30px',
                    height: '30px',
                    backgroundColor: '#000000'
                  }}
                  onClick={() => {
                    toggleSidebar();
                    toggleSidebarMobile();
                  }}
                >
                  {isSidebarHidden ? <ArrowForwardIosIcon fontSize={'small'} /> : <ArrowBackIosNewIcon fontSize={'small'} />}
                </IconButton>
              </Tooltip>
              <Outlet />
            </Stack>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
