import { date } from 'yup';
import { UserInfo, UserInfoResponse } from '../@types/auth';
import axios from '../utils/axios';

const getUserInfo = async (): Promise<UserInfo> => {
  const { data: userInfo } = await axios.get<UserInfoResponse>('auth/user-info').then(res => {
    return res;
  });

  return userInfo.user;
};

const resendEmailVerification = async (): Promise<void> => {
  await axios.post<void>('auth/resend-email-verification');
};

const getUsedStorage = async (): Promise<number> => {
  return await axios.get<number>('auth/get-used-storage').then(data => data.data);
};

const checkEmailVerification = async (): Promise<boolean> => {
  const { data } = await axios.get<{ isEmailVerified: boolean }>('auth/check-email-verification');

  return data.isEmailVerified;
};

const createUser = async (userId: string): Promise<void> => {
  await axios.post('auth/create-user', { userId, guestToken: localStorage.getItem('guest_user_token') });
};

const createGuestUser = async (): Promise<{ guestToken: string; id: string; email: string; name: string }> => {
  return axios.post('auth/create-guest-user').then(res => res.data.guestUser);
};

const authService = {
  getUserInfo,
  createUser,
  resendEmailVerification,
  checkEmailVerification,
  getUsedStorage,
  createGuestUser
};

export default authService;
