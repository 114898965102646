import {
  AutofillEmails,
  BulkShareByInviteRequest,
  ShareByInviteRequest,
  SharedUserInfo,
  ShareFolderType,
  SharedUserRoles
} from '../@types/sharing';
import axios from '../utils/axios';

const shareByInvite = async (data: ShareByInviteRequest) => {
  await axios.post('share/share-invite', data);
};
const shareFolderByInvite = async (data: ShareFolderType) => {
  await axios.post('share/share-folder-invite', data);
};

const bulkShareByInvite = async (data: BulkShareByInviteRequest) => {
  await axios.post('share/share-invite-multi', data);
};

const getSharedUsersBySongId = async (songId: string): Promise<SharedUserInfo[]> => {
  return await axios.get<SharedUserInfo[]>(`share/${songId}/users`).then(res => res.data);
};

const getSharedUsersByFolderId = async (folderId: string): Promise<SharedUserInfo[]> => {
  return await axios.get<SharedUserInfo[]>(`share/folder/${folderId}/users`).then(res => res.data);
};

const getSharedUsersAutofill = async (): Promise<AutofillEmails> => {
  return await axios.get<AutofillEmails>(`share/shared-users`).then(res => res.data);
};

const deleteFolderSharedUser = async (email: string, folderId: string) => {
  await axios.post('share/delete-folder-sharing', { email, folderId });
};

const deleteSharedUser = async (email: string, songId: string) => {
  await axios.post('share/delete-sharing', { email, songId });
};

const getSongAccessType = async (songId: string): Promise<SharedUserRoles> => {
  const response = await axios.get<{ access: SharedUserRoles }>(`share/${songId}/get-access`);
  return response.data.access;
};

const getFolderAccessType = async (folderId: string): Promise<string> => {
  const response = await axios.get<{ access: SharedUserRoles }>(`share/folder/${folderId}/get-access`);
  return response.data.access;
};

const sharingService = {
  shareByInvite,
  bulkShareByInvite,
  getSharedUsersBySongId,
  deleteSharedUser,
  shareFolderByInvite,
  getSharedUsersByFolderId,
  getSharedUsersAutofill,
  deleteFolderSharedUser,
  getSongAccessType,
  getFolderAccessType
};

export default sharingService;
